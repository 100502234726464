import { Box, Modal } from "@mui/material";
import { useApp } from "../hooks/useApp";
import React from "react";
import "./Factorymap.css";

export default function Videoplayer() {
  const { activeVideo, setActiveVideo } = useApp();
  const isVideo = activeVideo
    ? activeVideo.includes("/video/") || activeVideo.includes("wistia")
    : null;
  const handleClose = () => {
    setActiveVideo(null);
  };
  return (
    <React.Fragment>
      <Modal
        open={activeVideo}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
          }}
          className="w-11/12 lg:w-9/12"
        >
          <div
            style={{
              position: "relative",
              width: "100%",
              height: 0,
              paddingBottom: "56.25%",
            }}
          >
            {isVideo ? (
              <iframe
                src={activeVideo}
                webkitallowfullscreen
                mozallowfullscreen
                allowfullscreen
                muted
                frameborder="0"
                allowtransparency="true"
                scrolling="no"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
              ></iframe>
            ) : (
              <img style={{ width: "100%" }} src={activeVideo} />
            )}
          </div>
        </Box>
      </Modal>
    </React.Fragment>
  );
}
