import * as React from "react";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useApp } from "../hooks/useApp";
import { useAuth } from "../hooks/useAuth";

export default function Togglebuttons() {
  const [formats, setFormats] = React.useState(() => []);
  const { beginToggle } = useApp();
  const { bookmarkFilter, setBookmarkFilter } = useApp();
  const { user } = useAuth();

  const handleFormat = (event, newFormats) => {
    setFormats(newFormats);
    beginToggle(newFormats);
  };

  const handleBookmarker = (event, newFormats) => {
    setBookmarkFilter(newFormats);
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: "#00263e",
        light: "#00263e",
        dark: "#00263e",
        contrastText: "#fff",
      },
      // You can customize success, error, info, warning colors here
    },
  });

  return (
    <ThemeProvider theme={theme} >
      <article className="w-full flex align-items-center">
      <ToggleButtonGroup
        value={formats}
        variant="solid"
        onChange={handleFormat}
        aria-label="text formatting"
        color="primary"
        className="w-full"
      >
        <ToggleButton value="factory" aria-label="factories" className="w-full !text-xs lg:!text-sm">
          Factories
        </ToggleButton>
        <ToggleButton value="beacon" aria-label="apps" className="w-full !text-xs lg:!text-sm">
          Apps
        </ToggleButton>
        <ToggleButton value="video" aria-label="videos" className="w-full !text-xs lg:!text-sm">
          Videos
        </ToggleButton>
      </ToggleButtonGroup>
      <ToggleButtonGroup
        variant="solid"
        value={bookmarkFilter}
        onChange={handleBookmarker}
        aria-label="text formatting"
        color="primary"
        className=""
      >
        {!user.privateId && (
          <ToggleButton value="bookmarks" aria-label="bookmarks" className="w-full !text-xs lg:!text-sm">
            Bookmarks
          </ToggleButton>
        )}
      </ToggleButtonGroup>
      </article>
    </ThemeProvider>
  );
}
