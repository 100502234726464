import "../pages/composable/Composable.css";
import { useEffect, useState } from "react";
import CmesTabs from "../pages/composable/CmesTabs";
import FactoryAndPanel from "../pages/composable/FactoryAndPanel";
import Process from "./Process";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useApp } from "../hooks/useApp";
import MockPc from "../pages/composable/MockPc";
import Ipad from "../pages/composable/Ipad";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import { PoweredByLogo } from "./Common/PoweredByLogo";
import { GroupButtons } from "./Common/GroupButtons";
import { CustomButton } from "./Common/CustomButton";

export default function MegaFactoryContent({
  activeMegaFactory,
  isModal = true,
  isFactoryDirect = false,
}) {
  const {
    setActiveMockPc,
    setActiveMegaFactory,
    vtecAssetsUnsorted: vtecAssets,
  } = useApp();
  const [exampleData, setExampleData] = useState(null);
  const [tab, setTab] = useState("Composable");
  const [selectedSuite, setSelectedSuite] = useState(null);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [cmesData, setCmesData] = useState(null);
  const theme = useTheme();

  // Helper function to find an asset by ID
  const findAssetById = (id, assets) => assets.find((asset) => asset.id === id);

  // Helper function to update children with real assets
  const updateChildrenWithRealAssets = (children, vtecAssets) => {
    return children.map((child) => {
      const realAsset = findAssetById(child.id, vtecAssets);
      return realAsset ? { ...realAsset, ...child } : child;
    });
  };

  // Function to update suites with factory information
  const updateSuitesWithFactory = (asset, vtecAssets) => {
    if (!asset) return null;

    return {
      ...asset,
      suites: asset.suites.map((suite) => {
        const factory = findAssetById(suite.factoryId, vtecAssets);
        if (!factory) return suite;

        const updatedChildren = updateChildrenWithRealAssets(
          factory.Children,
          vtecAssets
        );

        return {
          ...suite,
          factory: {
            ...factory,
            Children: updatedChildren,
            color: suite.color || theme.palette.primary.main,
            discoverHeader: suite.textHeader,
            discover: suite.text,
          },
        };
      }),
    };
  };

  // Map version 1 mega factory assets
  const mapVersion1MegaFactory = (vtecAssets, activeMegaFactory) => {
    const cMES =
      findAssetById(activeMegaFactory["megafactory"], vtecAssets)?.Suites || [];
    const exampleFlow = findAssetById(
      activeMegaFactory["exampleflow"],
      vtecAssets
    );

    const cmesSuites = cMES.map((suite) => ({
      ...suite,
      color: theme.palette.info.main,
      Children: updateChildrenWithRealAssets(suite.Children, vtecAssets),
    }));

    setCmesData(cmesSuites);
    setSelectedSuite(cmesSuites[0]);

    if (exampleFlow) {
      setExampleData({
        ...exampleFlow,
        color: theme.palette.info.main,
        Children: updateChildrenWithRealAssets(
          exampleFlow.Children,
          vtecAssets
        ),
      });
    }
  };

  // Map version 2 mega factory assets
  const mapVersion2MegaFactory = (vtecAssets, activeMegaFactory) => {
    const newAsset = updateSuitesWithFactory(activeMegaFactory, vtecAssets);
    if (newAsset?.exampleflow) {
      const exampleFlow = findAssetById(newAsset.exampleflow, vtecAssets);
      setExampleData({
        ...exampleFlow,
        color: newAsset.exampleColor || theme.palette.primary.main,
        Children: updateChildrenWithRealAssets(
          exampleFlow.Children,
          vtecAssets
        ),
        discoverHeader: newAsset.exampleHeader,
        discover: newAsset.exampleText,
      });
    }

    const updatedSuites = newAsset.suites.map((item) => item.factory);
    setCmesData(updatedSuites);
    setSelectedSuite(updatedSuites[0]);
  };

  // Main asset mapping function
  const mapAsset = (vtecAssets, activeMegaFactory) => {
    if (activeMegaFactory) {
      if (
        activeMegaFactory.hasOwnProperty("megafactory") &&
        activeMegaFactory["megafactory"]
      ) {
        mapVersion1MegaFactory(vtecAssets, activeMegaFactory);
      } else {
        mapVersion2MegaFactory(vtecAssets, activeMegaFactory);
      }
    }
  };

  // Effect hook to map assets on component mount
  useEffect(() => {
    mapAsset(vtecAssets, activeMegaFactory);
  }, [vtecAssets, activeMegaFactory]);

  const handleClose = () => {
    setActiveMegaFactory(null);
  };
  const handleMockPc = (url) => {
    setActiveMockPc(url);
  };

  const handleTab = (tab) => {
    setTab(tab);
    setSelectedAsset(null);
  };

  const handleSuiteChange = (newSuiteTitle) => {
    const newSuite = cmesData.filter(
      (suite) => suite.Title === newSuiteTitle
    )[0];
    setSelectedSuite(newSuite);
    setSelectedAsset(null);
  };

  const ExampleButtons = [
    {
      title: activeMegaFactory?.Title,
      name: "Composable",
      click: (e) => handleTab(e),
    },
    {
      title: exampleData?.Title,
      name: "Example",
      click: (e) => handleTab(e),
    },
  ];
  const shouldShowButton =
    activeMegaFactory?.noLibrary === false ||
    (activeMegaFactory?.showLibrary === true || activeMegaFactory?.showLibrary === "true") ||
    (activeMegaFactory?.library &&
      (activeMegaFactory.library.buttonType === "library" ||
        activeMegaFactory.library.buttonType === "custom"));

  let buttonText = "Add More From Library";
  let buttonURL = "https://tulip.co/library/";

  if (activeMegaFactory?.library?.buttonType === "custom") {
    buttonText = activeMegaFactory.library.customButtonText;
    buttonURL = activeMegaFactory.library.customButtonUrl;
  }
  return (
    <div
      className="standardGradient"
      style={{
        display: "flex",
        flexDirection: "column",
        paddingLeft: "20px",
        paddingRight: "20px",
        paddingTop: "40px",
      }}
    >
      {isModal === true && (
        <CloseIcon
          style={{
            position: "absolute",
            top: "20px",
            right: "20px",
            color: theme.palette.customColors.white,
            cursor: "pointer",
          }}
          onClick={() => {
            handleClose();
          }}
        />
      )}

      {selectedSuite && tab !== "Example" && (
        <CmesTabs
          handleSuiteChange={handleSuiteChange}
          selectedSuite={selectedSuite}
          suites={cmesData}
          tab={tab}
        />
      )}
      {tab === "Example" &&
        exampleData.Children.some((child) => child.flow) && (
          <Process
            beaconData={exampleData.Children}
            selectedAsset={selectedAsset}
            setSelectedAsset={setSelectedAsset}
          />
        )}
      {cmesData && (
        <FactoryAndPanel
          imgHeight={tab === "Example" ? 200 : 200}
          selectedAsset={selectedAsset}
          setSelectedAsset={setSelectedAsset}
          selectedSuite={tab === "Composable" ? selectedSuite : exampleData}
          nodes={tab === "Example" ? exampleData : null}
        />
      )}

      <div className="bottom-buttons w-full absolute flex align-items-center items-center text-center md:justify-between pb-3 flex-col md:flex-row bottom-[10px] left-[0] px-[20px]">
        {exampleData ? (
          <GroupButtons tab={tab} buttonsArray={ExampleButtons} />
        ) : (
          <div></div>
        )}
        {shouldShowButton && (
          <CustomButton
            color={"info"}
            endIcon={<OpenInNewIcon />}
            onClick={() => handleMockPc(buttonURL)}
            style={{
              borderRadius: "100px",
              fontSize: "14px",
              padding: "9px",
              fontFamily: "Messina Sans light",
              textTransform: "none",
            }}
          >
            {buttonText}
          </CustomButton>
        )}
      </div>
      <MockPc />
      <Ipad />
      {isFactoryDirect && (
        <PoweredByLogo
          style={{
            position: "absolute",
            bottom: "10px",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        />
      )}
    </div>
  );
}
