import { Box, Typography, Autocomplete, TextField } from "@mui/material";
import Paper from "@mui/material/Paper";
import Navbar from "../../components/Navbar";
import { useEffect, useState, useMemo } from "react";
import FactoryStepper from "./FactoryStepper";
import FactoryOne from "./FactoryOne";
import FactoryTwo from "./FactoryTwo";
import FactoryThree from "./FactoryThree";
import FactoryFeatures from "./FactoryFeatures";
import FactoryExample from "./FactoryExample";
import FactoryPreview from "./FactoryPreview";
import { useApp } from "../../hooks/useApp";
import { useAuth } from "../../hooks/useAuth";
import { useAdmin } from "../../hooks/useAdmin";

const defaultAsset = {
  Title: "",
  Heading: "",
  Features: [],
  Children: [],
  Image: "",
  desc: "",
  Type: "app",
  img: "",
  private: "",
  status: "REVIEW",
};
const factorySteps = [
  "Choose Factory Type",
  "Define Factory",
  "Add Features",
  "Add Assets",
  "Preview",
];
const megaFactorySteps = [
  "Choose Factory Type",
  "Add Factories",
  "Add Features",
  "Add Example",
  "Preview",
];
const editFactorySteps = [
  "Choose Factory",
  "Edit Factory",
  "Edit Features",
  "Edit Assets",
  "Preview",
];

export default function FactoryCreate({ isEdit = false }) {
  const [asset, setAsset] = useState(null);
  const [activeStep, setActiveStep] = useState(0);
  const [steps, setSteps] = useState(
    isEdit === true ? editFactorySteps : factorySteps
  );
  const [vtecAssets, setVtecAssets] = useState([]);

  const { combinedAssets } = useApp();
  const { user } = useAuth();
  const { myAdminRecord } = useAdmin();

  // Memoize the filtered factories to avoid recalculating on each render
  const factories = useMemo(() => {
    return combinedAssets?.filter((asset) => asset.Type === "factory") || [];
  }, [combinedAssets]);

  // Filter assets based on user and admin status
  const filteredAssets = useMemo(() => {
    if (!user) return [];
    if (myAdminRecord?.admin) return factories;
    return factories.filter((item) => item.userid === user.uid);
  }, [user, myAdminRecord, factories]);

  useEffect(() => {
    if (isEdit) {
      setSteps(editFactorySteps);
    } else {
      setSteps(factorySteps);
    }
  }, [isEdit]);
  // Update the asset list only if filteredAssets changes
  useEffect(() => {
    setVtecAssets(filteredAssets);
  }, [filteredAssets]);

  // Update steps when asset or isEdit changes
  useEffect(() => {
    if (asset) {
      const newSteps = isEdit
        ? editFactorySteps
        : asset.Type === "factory"
        ? factorySteps
        : megaFactorySteps;
      setSteps(newSteps);
    }
  }, [asset]);

  function resetAsset() {
    setAsset(defaultAsset);
  }
  const handleFactoryChange = (asset) => {
    if (!("Children" in asset)) return;

    const newChildren = asset.Children.map((child) => {
      const assetObject =
        typeof child === "string"
          ? combinedAssets.find((title) => title.id === child)
          : combinedAssets.find((title) => title.id === child.id);

      if (typeof child === "string") {
        return {
          id: child,
          Title: assetObject ? assetObject.Title : "",
          Top: assetObject?.Top || "50%",
          Left: assetObject?.Left || "50%",
        };
      } else if (typeof child === "object" && !child.hasOwnProperty("Title")) {
        return {
          ...child,
          Title: assetObject ? assetObject.Title : "",
        };
      }

      return child;
    });

    const updatedData = {
      ...asset,
      Children: newChildren,
    };

    setAsset(updatedData);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "column",
          alignItems: "center",
          gap: 4,
        }}
      >
        <Navbar />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "column",
            alignItems: "center",
            gap: 4,
          }}
          className="px-3 md-px-0 w-full md:w-[80%] step-overflow"
        >
          <Typography variant="h5" sx={{ alignSelf: "start" }}>
            {isEdit === true ? "Edit vTEC Factory" : "Create vTEC Factory"}
          </Typography>
          <FactoryStepper
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            asset={asset}
            resetAsset={resetAsset}
            steps={steps}
          >
            <Paper
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                flexDirection: "column",
                padding: 4,
                gap: 2,
              }}
              elevation={4}
              className="md:m-2"
            >
              {activeStep === 0 &&
                (isEdit ? (
                  vtecAssets && (
                    <Autocomplete
                      disablePortal
                      id="factorySelect"
                      options={vtecAssets}
                      getOptionLabel={(option) => option.Title} // Specify which property to use as the label
                      sx={{ width: "100%" }}
                      onChange={(event, newValue) => {
                        handleFactoryChange(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Title" />
                      )}
                    />
                  )
                ) : (
                  <FactoryOne asset={asset} setAsset={setAsset} />
                ))}
              {activeStep === 1 && (
                <FactoryTwo asset={asset} setAsset={setAsset} />
              )}
              {activeStep === 2 && (
                <FactoryFeatures asset={asset} setAsset={setAsset} />
              )}
              {activeStep === 3 &&
                (asset.Type === "factory" ? (
                  <FactoryThree asset={asset} setAsset={setAsset} />
                ) : (
                  <FactoryExample asset={asset} setAsset={setAsset} />
                ))}
              {activeStep === 4 && <FactoryPreview asset={asset} />}
            </Paper>
          </FactoryStepper>
        </Box>
      </Box>
    </>
  );
}
