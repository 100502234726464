import React from "react";
import AssetPanel from "./AssetPanel";
import Lines from "./Lines";
import { useTheme } from "@mui/material/styles";

const generateRandomDelay = () => Math.random() * 1;

export default function FactoryAndPanel({
  setSelectedAsset,
  selectedAsset,
  selectedSuite,
  imgHeight,
  nodes,
}) {
  const theme = useTheme();
  const youarehere = selectedSuite.Children.filter(
    (asset) => asset.youarehere
  )[0];

  const handleAsset = (selected) => {
    if (selectedAsset && selectedAsset.id == selected.id) {
      setSelectedAsset(null);
    } else {
      setSelectedAsset(selected);
    }
  };
  return (
    <div
      id="main-container"
      style={{
        display: "flex",
        flexDirection: "row",
        width: "calc(100vw - 40px)",
        height: "calc(100vh -  65px)",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        id="factory"
        style={{
          backgroundColor: "transparent",
          position: "relative",
          height: "fit-content",
          maxWidth: selectedAsset ? "70vw" : "70vw",
        }}
      >
        {nodes && <Lines nodes={nodes} />}
        <div
          id="beaconlayer"
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
          }}
        >
          {selectedSuite.Children.map((asset) => (
            <div
            key={asset.id}
              className="appbeacon-wrapper"
              style={{
                top: asset.Top,
                left: asset.Left,
                zIndex: selectedAsset && selectedAsset.id == asset.id ? 5 : 4,
                "--beaconcolor":
                  selectedAsset && selectedAsset.id == asset.id
                    ? theme.palette.customColors.highlight
                    : asset.color
                    ? asset.color
                    : selectedSuite.color,
                "--fontcolor":
                  selectedAsset && selectedAsset.id == asset.id
                    ? theme.palette.secondary.main
                    : theme.palette.customColors.white,
                "--delay": `${generateRandomDelay()}s`,
              }}
            >
              <div className="cappdot" />
              <div
                className={
                  selectedAsset && selectedAsset.id == asset.id
                    ? "appbeacon"
                    : "appbeacon vibey"
                }
                key={asset.id}
                id={asset.id}
                style={{
                  zIndex: "4",
                  color:
                    selectedAsset && selectedAsset.id == asset.id
                      ? theme.palette.secondary.main
                      : theme.palette.customColors.white,
                }}
                onClick={() => handleAsset(asset)}
              >
                {asset.Title}
              </div>
            </div>
          ))}

          {selectedSuite.Children.map((asset) => (
            <svg
              key={asset.id}
              width="100%"
              height="100%"
              style={{ position: "absolute", zIndex: 3 }}
            >
              <circle
                cx={asset.Left}
                cy={asset.Top}
                r={10}
                fill={theme.palette.secondary.main}
                stroke={"#FFF"}
                strokeWidth={2.5}
              />
              <circle cx={asset.Left} cy={asset.Top} r={5} fill={"#b6dce1"} />
            </svg>
          ))}

          {selectedSuite.Children.map(
            (asset) =>
              selectedAsset &&
              selectedAsset.id == asset.id && (
                <svg
                  key={asset.id}
                  width="100%"
                  height="100%"
                  style={{ position: "absolute", zIndex: 2 }}
                >
                  <line
                    x1={asset.Left}
                    y1={asset.Top}
                    x2={"calc(100% - 20px + 2.5px)"}
                    y2={asset.Top}
                    stroke={"#939EAB"}
                    strokeWidth={"5px"}
                  />
                  <line
                    x1={"calc(100% - 20px)"}
                    y1={asset.Top}
                    x2={"calc(100% - 20px)"}
                    y2={"50%"}
                    stroke={"#939EAB"}
                    strokeWidth={"5px"}
                  />
                  <line
                    x1={"calc(100% - 20px - 2.5px)"}
                    y1={"50%"}
                    x2={"100%"}
                    y2={"50%"}
                    stroke={"#939EAB"}
                    strokeWidth={"5px"}
                  />
                </svg>
              )
          )}

          {!selectedAsset && youarehere && (
            <svg
              key={youarehere.id}
              width="100%"
              height="100%"
              style={{ position: "absolute", zIndex: 2 }}
            >
              <line
                x1={youarehere.Left}
                y1={youarehere.Top}
                x2={"calc(100% - 20px + 2.5px)"}
                y2={youarehere.Top}
                stroke={"#939EAB"}
                strokeWidth={"5px"}
              />
              <line
                x1={"calc(100% - 20px)"}
                y1={youarehere.Top}
                x2={"calc(100% - 20px)"}
                y2={"42%"}
                stroke={"#939EAB"}
                strokeWidth={"5px"}
              />
              <line
                x1={"calc(100% - 20px - 2.5px)"}
                y1={"42%"}
                x2={"100%"}
                y2={"42%"}
                stroke={"#939EAB"}
                strokeWidth={"5px"}
              />
            </svg>
          )}
        </div>
        <img
          src={
            selectedSuite.img
              ? selectedSuite.img
              : "https://tulip.widen.net/content/u79nz7brvd/webp/transparent-factory-floor-compressed-.webp"
          }
          style={{
            maxWidth: "100%",
            maxHeight: `calc(100vh - ${imgHeight ? imgHeight : 180}px)`,
            opacity: 1,
            backgroundColor: "transparent",
          }}
        ></img>
      </div>
      <AssetPanel
        selectedAsset={selectedAsset}
        setSelectedAsset={setSelectedAsset}
        discover={selectedSuite.discover}
        discoverHeader={selectedSuite.discoverHeader ?? null}
        youarehere={youarehere}
      />
    </div>
  );
}
