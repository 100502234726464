import {
  Grid,
  Stack,
  TextField,
  Typography,
  FormControlLabel,
  FormControl,
  RadioGroup,
  Radio,
  Box,
} from "@mui/material";
import { useApp } from "../../hooks/useApp";
import Autocomplete from "@mui/material/Autocomplete";
import AssetCard from "../../components/Assetcard";
import { useState } from "react";

export default function FactoryExample({ asset, setAsset }) {
  const { combinedAssets } = useApp();

  const handleFactoryChange = (newValue) => {
    setAsset((prevAsset) => ({ ...prevAsset, exampleflow: newValue.id }));
  };
  const handleCheckboxChange = (event) => {
    setAsset((prevAsset) => ({
      ...prevAsset,
      showLibrary: event.target.checked,
    }));
  };
  const handleRadioButtonChange = (event) => {
    setAsset((prevAsset) => ({
      ...prevAsset,
      library: {
        buttonType: event.target.value,
        customButtonText: "",
        customButtonUrl: "",
      },
    }));
  };

  const getFactoryOptions = () =>
    combinedAssets.filter((ex) => ex.Type === "factory");
  const getSelectedFactory = () =>
    combinedAssets.find((ex) => ex.id === asset.exampleflow) || null;

  return (
    <Grid container sx={{ flexDirection: "row", gap: 4 }}>
      <Grid
        item
        sx={{
          justifyContent: "center",
          alignItems: "center",
          width: "fit-content",
        }}
        xs={12}
        md={5}
      >
        <AssetCard asset={asset} />
      </Grid>
      <Grid item xs>
        <Stack direction="column" gap={1} sx={{ height: "100%" }}>
          <Typography fontWeight="bold">Bottom Button</Typography>
          {/* <FormControlLabel
            control={
              <Checkbox
                checked={asset.showLibrary}
                onChange={handleCheckboxChange}
                name="showLibraryCheckbox"
                color="primary"
              />
            }
            label="Show library button"
          /> */}
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={asset.library.buttonType}
              onChange={handleRadioButtonChange}
            >
              <FormControlLabel
                value="noButton"
                control={<Radio />}
                label="No Button"
              />
              <FormControlLabel
                value="library"
                control={<Radio />}
                label="Library Button"
              />
              <FormControlLabel
                value="custom"
                control={<Radio />}
                label="Custom Button"
              />
            </RadioGroup>
          </FormControl>
          {asset.library.buttonType === "custom" && (
            <Box
            >
              <TextField
                id="customButtonText"
                label="Custom Button Text"
                fullWidth
                size="small"
                value={asset.library.customButtonText}
                sx={{
                  marginBottom: "8px",
                }}
                onChange={(e) => {
                  setAsset((prevAsset) => ({
                    ...prevAsset,
                    library: {
                      ...prevAsset.library,
                      customButtonText: e.target.value,
                    },
                  }));
                }}
              />
              <TextField
                id="customButtonUrl"
                label="Custom Button Url"
                fullWidth
                size="small"
                value={asset.library.customButtonUrl}
                onChange={(e) => {
                  setAsset((prevAsset) => ({
                    ...prevAsset,
                    library: {
                      ...prevAsset.library,
                      customButtonUrl: e.target.value,
                    },
                  }));
                }}
              />
            </Box>
          )}
          <Typography fontWeight="bold">Factory Example</Typography>
          <Autocomplete
            disablePortal
            id="exampleSelect"
            options={getFactoryOptions()}
            getOptionLabel={(option) => option.Title}
            sx={{ width: "100%" }}
            size="small"
            value={getSelectedFactory()}
            onChange={(event, newValue) => handleFactoryChange(newValue)}
            renderInput={(params) => <TextField {...params} label="Example" />}
          />
          <TextField
            id="examplecolor"
            label="Example Color"
            fullWidth
            value={asset.exampleColor}
            size="small"
            onChange={(e) => {
              setAsset((prevAsset) => ({
                ...prevAsset,
                exampleColor: e.target.value,
              }));
            }}
          />
          <TextField
            id="exampletext1"
            label="Example Header"
            fullWidth
            value={asset.exampleHeader}
            size="small"
            onChange={(e) => {
              setAsset((prevAsset) => ({
                ...prevAsset,
                exampleHeader: e.target.value,
              }));
            }}
          />
          <TextField
            id="exampletext2"
            label="Example Text"
            fullWidth
            value={asset.exampleText}
            size="small"
            onChange={(e) => {
              setAsset((prevAsset) => ({
                ...prevAsset,
                exampleText: e.target.value,
              }));
            }}
          />
        </Stack>
      </Grid>
    </Grid>
  );
}
