import { useState, useEffect } from "react";
import { useCollection } from "./useCollection";

const sorter = (assets) => {
  return assets
    ? assets.sort((a, b) => {
        if (
          a.Type &&
          b.Type &&
          a.Type.includes("megafactory") &&
          !b.Type.includes("megafactory")
        ) {
          return -2; // a comes first
        } else if (
          a.Type &&
          b.Type &&
          a.Type === "factory" &&
          b.Type !== "megafactory"
        ) {
          return -1; // b comes first
        } else {
          return 0; // maintain the current order
        }
      })
    : null;
};


// const sorter = (assets) => {
//   return assets
//     ? assets.sort((a, b) => {
//       const aUpVotes = a.upVote || 0;
//       const aDownVotes = a.downVote || 0;
//       const bUpVotes = b.upVote || 0;
//       const bDownVotes = b.downVote || 0;
  
//       const aScore = aUpVotes - aDownVotes;
//       const bScore = bUpVotes - bDownVotes;
  
//       return bScore - aScore;
//     })
//     : null;
// };

const arraySmasher = (vtecAssets, libAssets) => {
  if (vtecAssets && libAssets) {
    const arrayC = [];

    // Add objects from arrayA to arrayC
    vtecAssets.forEach((objA) => {
      const matchingObjB = libAssets.find((objB) => objB.id === objA.id);

      // Merge fields from arrayB into arrayA only if they exist in arrayB
      const mergedObject = { ...objA, ...(matchingObjB || {}) };
      arrayC.push(mergedObject);
    });

    // Add objects from arrayB to arrayC that don't exist in arrayA
    libAssets.forEach((objB) => {
      const matchingObjA = vtecAssets.find((objA) => objA.id === objB.id);

      // Add object from arrayB to arrayC only if it doesn't exist in arrayA
      if (!matchingObjA) {
        arrayC.push(objB);
      }
    });

    return arrayC;
  } else {
    return null;
  }
};

export const useSources = () => {
  const [combinedAssets, setCombinedAssets] = useState(null);
  const [reviewAssets, setReviewAssets] = useState(null);
  const [error, setError] = useState(null);
  const [pending, setPending] = useState(false);
  const { documents: vtecAssetsUnsorted } = useCollection("vtec_assets");
  //const { libAssets } = useLibrary()
  const libAssets = [];

  //fires once and whenever the collection changes
  useEffect(() => {
    if (vtecAssetsUnsorted) {
      const hasTitles = vtecAssetsUnsorted.filter((asset) => asset.Title);
      const noReview = hasTitles.filter(
        (asset) => !asset.status || asset.status !== "REVIEW"
      );
      const needReview = hasTitles.filter(
        (asset) => asset.status && asset.status == "REVIEW"
      );
      //const smashed = arraySmasher(noReview,libAssets) // joins lib assets with vtec assets
      const smashed = noReview; //override, remove the lib assets
      const filterNoTitles = smashed.filter(
        (asset) => asset.Title && asset.Heading && asset.Image && asset.Type
      );
      const sortedAssets = sorter(filterNoTitles);
      setCombinedAssets(sortedAssets);
      setReviewAssets(needReview);
    }
  }, [vtecAssetsUnsorted]); // Empty dependency array ensures the effect runs only once on component mount

  return { combinedAssets, pending, error, reviewAssets, vtecAssetsUnsorted };
};
