import React, { useEffect } from "react";
import "./Vtec.css";
import Grid from "@mui/material/Grid";
import Navbar from "../../components/Navbar";
import AssetCard from "../../components/Assetcard";
import Searchpanel from "../../components/Searchpanel";
import Freesolo from "../../components/Freesolo";
import Togglebuttons from "../../components/Togglebuttons";
import { useApp } from "../../hooks/useApp";
import Assetmodal from "../../components/Assetmodal";
import Oppselect from "../../components/Oppselect";
import { Stack } from "@mui/system";
import { Button, Typography } from "@mui/material";
import Oppdrawer from "../../components/Oppdrawer";
import Ipad from "../composable/Ipad";
import { useAuth } from "../../hooks/useAuth";
import { useTheme } from "@mui/material/styles";
import { useState, useMemo } from "react";
// import { useDocs } from "../../hooks/useDocs";
// import utility from "../../Utils/utility";
import { useCollection } from "../../hooks/useCollection";

export default function Vtec({ isPreview = false }) {
  const {
    searchState,
    vtecAssets,
    selectedAsset,
    selectedOpp,
    fakeOpportunity,
    setSelectedOpp,
  } = useApp();
  const { documents } = useCollection("partners");
  // const { updateMultipleDocuments } = useDocs("partners");
  const { documents: opportunities } = useCollection("opportunities");
  const theme = useTheme();
  const { user } = useAuth();
  // const [userVotes, setUserVotes] = useState([]);
  const [assets, setAssets] = useState();
  // const [voteDetails, setVoteDetails] = useState(null);

  useEffect(() => {
    // if (user && user?.votes) {
    //   setUserVotes(user?.votes);
    // }
    if (isPreview) {
      setSelectedOpp(fakeOpportunity);
    }
  }, [isPreview]);

  let assetList = searchState?.filteredAssets || vtecAssets;

  const isWithinLast7Days = (date) => {
    const now = new Date();
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(now.getDate() - 7);

    return date >= sevenDaysAgo && date <= now;
  };
  useEffect(() => {
    if (assetList) {
      const initialAssets = assetList;
      const newAssets =
        initialAssets &&
        initialAssets.map((item) => {
          // const userVoteObj = userVotes.find((vote) =>
          //   vote.hasOwnProperty(item.id)
          // );
          let partnerName = "";
          //const userVote = userVoteObj ? userVoteObj[item.id] : null;
          if (item.hasOwnProperty("userid")) {
            const partner =
              documents && documents.find((x) => x.id === item.userid);
            if (partner?.privateId) {
              const opps =
                opportunities &&
                opportunities.find((opp) => opp.id === partner?.privateId);
              if (opps.hasOwnProperty("name")) {
                partnerName = opps?.name ? opps.name : "Partner";
              }
            }
          }
          let isNew = false;
          if (item?.createdAt) {
            isNew = isWithinLast7Days(item.createdAt.toDate());
          }

          return {
            ...item,
            //userVote,
            partnerName,
            isNew,
          };
        });
      setAssets(newAssets);
      // if (userVotes) {

      // } else {
      //   setAssets(initialAssets);
      // }
    }
  }, [assetList, documents, opportunities]);

  const handleVote = (asset, voteType) => {
    // let updateUserVotes;
    // let removeVote = false;
    // let toggleVote = false;
    // setUserVotes((prevVotes) => {
    //   const existingVoteIndex = prevVotes.findIndex((vote) =>
    //     vote.hasOwnProperty(asset.id)
    //   );
    //   const voteValue = voteType === "up";
    //   if (existingVoteIndex !== -1) {
    //     const vote = prevVotes[existingVoteIndex];
    //     if (
    //       (vote[asset.id] && voteType === "up") ||
    //       (!vote[asset.id] && voteType === "down")
    //     ) {
    //       updateUserVotes = prevVotes.filter(
    //         (_, index) => index !== existingVoteIndex
    //       );
    //       removeVote = true;
    //     } else if (
    //       (!vote[asset.id] && voteType === "up") ||
    //       (vote[asset.id] && voteType === "down")
    //     ) {
    //       updateUserVotes = [...prevVotes];
    //       updateUserVotes[existingVoteIndex] = { [asset.id]: voteValue };
    //       toggleVote = true;
    //     } else {
    //       updateUserVotes = [...prevVotes];
    //       updateUserVotes[existingVoteIndex] = { [asset.id]: voteValue };
    //     }
    //   } else {
    //     updateUserVotes = [...prevVotes, { [asset.id]: voteValue }];
    //   }
    //   return updateUserVotes;
    // });
    // const { upVote, downVote } = asset;
    // let newUpVote = upVote || 0;
    // let newDownVote = downVote || 0;
    // if (removeVote) {
    //   if (voteType === "up") newUpVote -= 1;
    //   else newDownVote -= 1;
    // } else if (toggleVote) {
    //   if (voteType === "up") {
    //     newUpVote += 1;
    //     newDownVote -= 1;
    //   } else {
    //     newUpVote -= 1;
    //     newDownVote += 1;
    //   }
    // } else {
    //   if (voteType === "up") newUpVote += 1;
    //   else newDownVote += 1;
    // }
    // setAssets((prevAssets) => {
    //   return prevAssets.map((item) => {
    //     if (item.id === asset.id) {
    //       return {
    //         ...item,
    //         upVote: newUpVote,
    //         downVote: newDownVote,
    //         userVote: removeVote ? null : voteType === "up" ? true : false,
    //       };
    //     }
    //     return item;
    //   });
    // });
    // const updatedAsset = {
    //   ...asset,
    //   upVote: newUpVote,
    //   downVote: newDownVote,
    // };
    // setVoteDetails({
    //   asset: updatedAsset,
    //   removeVote,
    //   voteType,
    //   updateUserVotes,
    // });
  };
  // const updateVoteDetails = utility.debounce(async () => {
  //   if (voteDetails) {
  //     const { asset, updateUserVotes } = voteDetails;
  //     try {
  //       const updates = [
  //         {
  //           collection: "partners",
  //           id: user.uid,
  //           data: { votes: updateUserVotes },
  //         },
  //         {
  //           collection: "vtec_assets",
  //           id: asset.id,
  //           data: {
  //             upVote: asset.upVote,
  //             downVote: asset.downVote,
  //           },
  //         },
  //       ];
  //       await updateMultipleDocuments(updates);
  //     } catch (error) {
  //       console.error("Error updating documents: ", error);
  //     }
  //     // Clear voteDetails to avoid re-running this effect
  //     setVoteDetails(null);
  //   }
  // }, 500);
  // useEffect(() => {
  //   updateVoteDetails();
  // }, [voteDetails]);

  return (
    <Grid container justifyContent={"center"} className="vtec-container">
      <Navbar sx={{ backgroundColor: "grey" }} />
      {selectedOpp && (
        <article
          className={`px-6 xl:px-24 w-full sticky top-[65px] z-10 bg-[${theme.palette.background.default}]`}
        >
          <Grid
            container
            className="lg:flex md:!flex-nowrap"
            gap={2}
            paddingY={2}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <article className="md:flex items-center gap-2 md-gap-3 w-full ">
              <Grid item xs className="!mb-3 md:!mb-0">
                <Searchpanel />
              </Grid>
              <Grid item xs>
                <Freesolo />
              </Grid>
            </article>
            <Grid item xs>
              <Togglebuttons />
            </Grid>
          </Grid>
        </article>
      )}
      {selectedOpp && (
        <Grid
          className="assets-cards"
          container
          paddingBottom={10}
          sx={{ padding: "0px 10px" }}
        >
          <Oppdrawer />
          <Grid container gap={6} justifyContent={"center"} marginTop={2}>
            {assets &&
              assets.map((asset) => {
                return (
                  <AssetCard
                    item
                    key={asset.id}
                    asset={asset}
                    onVoteClick={handleVote}
                  ></AssetCard>
                );
              })}
          </Grid>
        </Grid>
      )}
      {!user.privateId && !selectedOpp && (
        <Grid
          container
          alignItems={"center"}
          justifyContent={"center"}
          paddingBottom={10}
          sx={{
            overflowY: "auto",
            height: "calc(100vh - 100px)",
            padding: "0 10px",
          }}
        >
          <Grid
            item
            xs={12}
            sm={11}
            md={10}
            xl={6}
            backgroundColor={"#FFF"}
            padding={4}
            borderRadius={4}
            boxShadow={"5px 5px 5px 0px rgba(1,1,1,.2)"}
          >
            <Stack spacing={2} direction="column">
              <Typography variant="h6" color={"customColors.default"}>
                Select Opportunity
              </Typography>
              <Grid container justifyContent={"space-between"} spacing={1}>
                {true && (
                  <Grid item xs={12} sm={4} md={3} className="!pl-0">
                    <Button
                      onClick={() => {
                        setSelectedOpp(fakeOpportunity);
                      }}
                      sx={{ width: "100%" }}
                      variant="contained"
                    >
                      No Opportunity
                    </Button>
                  </Grid>
                )}
                <Grid item xs={12} sm={8} md={9} className="!pl-0 md:!pl-2">
                  {" "}
                  <Oppselect />
                </Grid>
              </Grid>
              <Typography color={"customColors.default"}>
                Thank you for selecting an opportunity. It's very important that
                we capture the usage of this tool so that we can improve its
                effectiveness
              </Typography>
              <Typography color={"customColors.default"}>
                Choosing an opportunity will allow you to create a curated
                selection for your discussion! You can also write notes that
                will be automatically saved.
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      )}
      {selectedAsset && <Assetmodal />}

      <Ipad />
    </Grid>
  );
}
